<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6" class="d-inline-flex">
        <h3 class="pt-2">{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({
            content: '',
            contentType: '',
            name: ''
          })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col v-if="userSettings.display.showId" cols="1">
            <span>{{ $lang.header.id }}</span>
          </v-col>
          <v-col cols="3">
            <span>{{ $lang.labels.name }}</span>
          </v-col>
          <v-col cols="3">
            <span>{{ $lang.labels.contentType }}</span>
          </v-col>
          <v-col cols="4" class="px-1">
            <span>{{ $lang.labels.content }}</span>
          </v-col>
          <v-col cols="2" class="text-right">
            <span>{{ $lang.labels.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col v-if="userSettings.display.showId" cols="1" style="padding-top: 14px">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="3" class="pr-1" style="padding-top: 14px">
              <v-text-field
                v-model="item.name"
                dense
                required
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit"
                :data-cy="`${type}-name-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="px-1">
              <v-combobox
                v-model="item.contentType"
                full-width
                dense
                :items="predefinedValues"
                :readonly="!canEdit"
                :data-cy="`${type}-contentType-${i}`"
                :rules="[v => !!v || $lang.labels.required]"
              ></v-combobox>
            </v-col>
            <v-col cols="4" class="px-1" style="padding-top: 14px">
              <v-text-field
                v-model="item.content"
                dense
                required
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit"
                :data-cy="`${type}-content-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { definitions } from '@/utils/definitions'
import { mapState } from 'vuex'
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    deconstructedData: [],
    predefinedValues: []
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler() {
        this.$emit('dataChanged', this.deconstructedData)
      },
      deep: true
    }
  },
  mounted() {
    this.predefinedValues = definitions.EmailStepAttachment?.properties?.contentType?.enum || []
  },
  created() {
    this.deconstructedData = this.data || []
  },
  methods: {
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
